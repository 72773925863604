<template>
  <footer id="kontakt">
    <div class="footer__logo">
      <img src="/images/logo/dny_ai.svg" alt="Logo" />
    </div>
    <div class="flex-wrap">
      <div class="half footer-container">
        <div class="footer-column">
          <h4>Sledujte nás</h4>
          <p>
            <a
              href="https://www.linkedin.com/showcase/dny-ai/"
              target="_blank"
              rel="noopener noreferrer"
              >LinkedIn</a
            >
          </p>
          <p>
            <a
              href="https://www.facebook.com/Dnyumeleinteligence"
              target="_blank"
              rel="noopener noreferrer"
              >Facebook</a
            >
          </p>
          <p>
            <a
              href="https://www.instagram.com/dnyaicz/"
              target="_blank"
              rel="noopener noreferrer"
              >Instagram</a
            >
          </p>
        </div>
        <div class="desktop">
          <p>© Dny AI 2025 | created by Brainz Studios</p>
        </div>
      </div>
      <div class="half flex-wrap">
        <div class="footer-container third">
          <div class="footer-column">
            <h4>Kontakt</h4>
            <p>
              <a
                href="mailto:ahoj@dny.ai"
                target="_blank"
                rel="noopener noreferrer"
                >ahoj@dny.ai</a
              >
            </p>
          </div>
        </div>
        <div class="footer-container third">
          <div class="footer-column">
            <h4>Info</h4>
            <p>
              <a
                href="https://prg.ai/zasady-ochrany-soukromi/"
                target="_blank"
                rel="noopener noreferrer"
                >Ochrana osobnich údajů</a
              >
            </p>
          </div>
        </div>
        <div class="footer-container third"></div>
      </div>
    </div>
    <div class="mobile flex-wrap mobile-row">
      <div class="footer-container half">
        <p>© Dny AI 2025 | created by Brainz Studios</p>
      </div>
    </div>
  </footer>
</template>

<style>
footer {
  padding: 24px 48px;
  box-sizing: border-box;
  background-color: black;
  color: white;

  @media (max-width: 767px) {
    padding: 24px;
  }
  @media (min-width: 1501px) {
    padding: 48px;
  }

  /* a {
    font-weight: 100;
  } */
}

.footer__logo {
  filter: invert(1);
  padding: 48px 0;
}

.footer-container {
  p {
    margin: 4px 0;
  }
  @media (min-width: 991px) {
    min-width: 180px;
    padding-right: 32px;
  }
  @media (min-width: 1501px) {
    min-width: 290px;
  }
}

.footer-column {
  height: 320px;
  @media (max-width: 991px) {
    height: auto;
    padding-top: 32px;
  }
  @media (min-width: 1501px) {
    height: 480px;
  }
}

.mobile-row {
  padding-top: 48px;
  align-items: flex-end;
}
</style>
